import React from 'react';

const RisingWords = ({words, className, leading="leading-[1.15]"}) => {
  return (
    <div
      className={`flex flex-wrap box-border text-black transition-all ${className} rising-text-anim`}
    >
      {words
        .split(" ")
        .map((word, index) => (
          <span
            key={`${word}-${index}`}
            className={`inline-flex box-border overflow-hidden relative mr-2 ${leading}`}
          >
            <span className="block relative">{`${word} `}</span>
          </span>
        ))}
    </div>
  );
};

export default RisingWords;
