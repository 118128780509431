import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { graphql } from "gatsby";
import Button from "../components/Button";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import RisingWords from "../components/RisingWords";
import { insurance } from "../data/pages/homepage";
import { assetArrayToObject } from "../lib/graphql-helpers";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "../components/links";
gsap.registerPlugin(ScrollTrigger);

const Industries = ({ data }) => {
  const industriesRef = useRef(null);

  const {
    industries: { edges: industryImages },
  } = data;

  const sortedIndustryImages = assetArrayToObject({
    useGatsbyImage: true,
    images: industryImages,
  });

  const animationsRef = useRef([]); // Store animation instances for cleanup

  useEffect(() => {
    // Cleanup any previous animations
    animationsRef.current.forEach((anim) => {
      anim.scrollTrigger?.kill(); // Properly kill the ScrollTrigger instance
      anim.kill(); // Kill the GSAP animation
    });
    animationsRef.current = []; // Clear the reference array

    if (industriesRef.current) {
      const risingTextAnimElements = Array.from(
        industriesRef.current.querySelectorAll(".rising-text-anim")
      );

      risingTextAnimElements.forEach((text) => {
        const subHeaderWords = Array.from(text.children);

        // Get the word elements to animate
        const wordElements = subHeaderWords.map((word) => word.children[0]);
        gsap.set(wordElements, { y: 40 });

        // Create GSAP animation for each word element
        wordElements.forEach((wordElement, index) => {
          const animation = gsap.to(wordElement, {
            y: 0,
            duration: 1,
            delay: index * 0.04,
            scrollTrigger: {
              trigger: wordElement,
              start: "top bottom",
              end: "bottom top",
              toggleActions: "play none none reset",
            },
          });

          // Store each animation for later cleanup
          animationsRef.current.push(animation);
        });
      });
    }

    // Cleanup function for when the component unmounts
    return () => {
      animationsRef.current.forEach((anim) => {
        anim.scrollTrigger?.kill(); // Properly kill the associated ScrollTrigger
        anim.kill(); // Kill the GSAP animation itself
      });
      animationsRef.current = []; // Clear the reference array
    };
  }, []);
  return (
    <div
      ref={industriesRef}
      className="flex flex-col items-center justify-center w-full"
    >
      <section className="flex flex-col items-center justify-center w-full">
        <div className="flex flex-col items-start justify-center w-full box-border px-8 pt-12 pb-8 sm:py-24 max-w-screen-2xl">
          <RisingWords
            className="text-black text-6xl sm:text-7xl md:text-8xl lg:text-9xl"
            words="Industries"
          />
        </div>
      </section>
      <section className="flex flex-col items-center justify-center w-full">
        <div className="flex flex-col items-start justify-center w-full box-border px-8 py-8 sm:py-12 md:py-24 max-w-screen-2xl gap-8">
          <RisingWords
            className="text-2xl text-black"
            words="Select an industry."
          />
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 items-start justify-center w-full box-border">
            {insurance.map((item) => {
              const { image, header, link } = item;
              return (
                <Link to={link}>
                  <div key={image + header} className="flex flex-col gap-2">
                    <div className="w-full overflow-hidden rounded-lg ">
                      <GatsbyImage
                        className="h-full w-full hover:scale-105 transition-all duration-300 ease-in-out brightness-90 hover:brightness-100"
                        alt={image}
                        image={sortedIndustryImages[image]}
                        loading="lazy"
                      />
                    </div>
                    <h4 className="text-black text-4xl font-normal">
                      {header}
                    </h4>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </section>
      <section className="w-full flex flex-col items-center justify-center max-w-screen-2xl protect-image-class rounded-md bg-white">
        <div className="w-full bg-white box-border flex flex-col items-center justify-center max-w-screen-2xl py-8 sm:py-12 md:py-16 px-8 box-border gap-8 px-8">
          <div className="w-full flex-col sm:px-8 py-8 sm:py-12 md:py-16 rounded-[48px] flex items-center justify-center gap-12">
            <RisingWords
              words="Don't see what you're looking for?"
              className="text-black xl:text-6xl lg:text-4xl md:text-2xl sm:text-xl text-4xl"
            />
            <div className="w-full max-w-[300px]">
              <Button theme="hollow-large-black" to="/contact">
                Get in touch.
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Industries;

export const query = graphql`
  query {
    industries: allFile(
      filter: {
        extension: { eq: "jpg" }
        relativeDirectory: { eq: "insurance" }
      }
    ) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            gatsbyImageData(width: 708, height: 400, placeholder: BLURRED)
            fluid {
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  }
`;
